import { reactive, ref, computed, toRaw } from "vue";
import { useStore } from "vuex";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import { getPayoutDetailList } from "@/API/checking/spotChecking";
const payoutDetail = () => {
  const column = ref([
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
      width:150,
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerName",
      width:280,
    },
    {
      title: "VIN",
      dataIndex: "vin",
      slots: {
        customRender: "vin",
      },
      width: 120
    },
    {
      title: "Dealer Trade",
      dataIndex: "dealerTrade",
      width:120,
    },
    {
      title: "Amount w/o VAT",
      dataIndex: "amountWOVat",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 120
    },
    {
      title: " Amount w VAT",
      dataIndex: "amountWVat",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 120
    }
  ]);
  const query = reactive({
    payoutRound: "",
    programName: "",
    totalAmountWVat: "",
    totalAmountWOVat: "",
    payoutRoundStatus: "",
    dealerCode: "",
    dealerName: "",
    vinNumber: "",
    programIds: "",
    queryDealerTrade: ""
  });
  // 从store里获取数据
  const { state, commit } = useStore();
  const curRow = state.spotChecking.curPayoutRowData;
  const getQuery = () => {
    query.payoutRound = curRow.payoutRound || "";
    query.programName = curRow.programName || "";
    query.totalAmountWVat = curRow.amountWVat || "";
    query.totalAmountWOVat = curRow.amountWOVat || "";
    query.payoutRoundStatus = curRow.payoutRoundStatus || "";
    query.programIds = curRow.programIds || [];
  }
  getQuery()
  // 表格列表查询
  const res = ref();
  const data = computed(() => {
    return res.value?.dealerAndVinVOList;
  });
  const search = () => {
    const params = {
      dealerCode: query.dealerCode,
      dealerName: query.dealerName,
      payoutRound: query.payoutRound,
      // programId: query.programId,
      programIds: query.programIds,
      vinNumber: query.vinNumber,
      queryDealerTrade: query.queryDealerTrade
    };
    getPayoutDetailList(params).then((data: any) => {
      res.value = data;
      // query.totalAmountWOVat = toThousandsAndTwoDecimal(data.totalAmountWOVat)
      // query.totalAmountWVat = toThousandsAndTwoDecimal(data.totalAmountWVat)
      query.payoutRoundStatus = data.payoutRoundStatus
    })
    // useMock<DataProps>(Data).then((data) => {
    //   res.value = data;
    // });
  };
  // reset
  const reset = () => {
    for (const key in query) {
      query[key] = "";
    }
    getQuery()
    search();
  };
  return {
    search,
    query,
    reset,
    column,
    data,
  };
};
export default payoutDetail;
